import React from 'react';
import Head from 'next/head';
import { replaceUnicodes } from ':lib/_i18n';
import { normalizeUrlPath } from ':helpers/string-helpers';
import { LOCALES, LOCALES_LIST } from '../locales';

const fallbackImage =
  'https://rippling.imgix.net/images/PreviewImage_Product.png?fm=png';

const DEPLOYMENT_URI =
  process.env.NEXT_PUBLIC_DOMAIN_ORIGIN || 'https://rippling.com';

const getLocaleRoutePrefix = (locale) => {
  const isEnUs = locale === LOCALES.EN_US;
  const localePrefix = isEnUs ? '' : `/${locale}`;
  return localePrefix;
};

const getIsI18nNotAvail = ({ pageLocale, pageLocaleDefaulted }) => {
  if (!pageLocale) {
    return false;
  }
  return pageLocaleDefaulted;
};

const HeadComponent = ({ head }) => {
  const { mode = {}, styles = {}, seo = {} } = head;

  const { criticalCSS, missingAtomicCSS } = styles;
  const { image, uri, description, _isIndexed, title, _canonicalUrl } = seo;
  const { isDevelopment, locale: pageLocale = LOCALES.EN_US } = mode;

  const seoImage = image || fallbackImage;

  const shouldIndex = _isIndexed && !isDevelopment;
  const isI18nNotAvail = getIsI18nNotAvail(mode);
  const robotsIndexContent =
    shouldIndex && !isI18nNotAvail ? 'index, follow' : 'noindex, nofollow';
  const botsIndexContent = `${robotsIndexContent}, max-snippet:-1, max-image-preview:large, max-video-preview:-1`;
  const sanitizedCanonical = normalizeUrlPath(_canonicalUrl);

  const alternateLocales = LOCALES_LIST.filter(
    (siteLocale) => siteLocale !== pageLocale
  );

  const canonicalLocalePrefix = getLocaleRoutePrefix(pageLocale);
  const pageUri = sanitizedCanonical || uri || '';

  const optimizelyCdnUrl = 'https://cdn.optimizely.com';
  const optimizelyScriptUrl = `${optimizelyCdnUrl}/js/30065010201.js`;


  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="profile" href="http://gmpg.org/xfn/11" />
      {sanitizedCanonical && (
        <link
          rel="canonical"
          href={`${DEPLOYMENT_URI}${canonicalLocalePrefix}${sanitizedCanonical}`}
        />
      )}
      <title>{replaceUnicodes(title)}</title>

      <link rel="preconnect" href={optimizelyCdnUrl} />
      <link href={optimizelyScriptUrl} rel="preload" as="script" />

      <meta name="description" content={description} />
      {/* indexing */}
      <meta name="robots" content={robotsIndexContent} />
      <meta name="googlebot" content={botsIndexContent} />
      <meta name="bingbot" content={botsIndexContent} />
      {/* i18n */}
      {LOCALES_LIST.map((loc, localeIndex) => {
        const localePrefix = getLocaleRoutePrefix(loc);
        return (
          <link
            rel="alternate"
            // these should be lang-region (lowercase + hyphen)
            hrefLang={loc === 'en-US' ? 'x-default' : loc.toLowerCase()}
            // en-US should always be "/"
            href={`${DEPLOYMENT_URI}${localePrefix}${pageUri}`}
            key={`link-locales-${localeIndex}`}
          />
        );
      })}
      {/* og data (i18n) */}
      <meta
        property={'og:locale'}
        // these should be lang_REGION (lowercase + _underscore_ + UPPER)
        content={pageLocale.replace(/-/, '_')}
      />
      {alternateLocales.map((siteLocale, siteLocaleIndex) => (
        <meta
          property={'og:locale:alternate'}
          content={siteLocale.replace(/-/, '_')}
          key={`meta-siteLocale-${siteLocaleIndex}`}
        />
      ))}
      {/* og data common */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      {uri && <meta property="og:url" content={`${DEPLOYMENT_URI}${uri}`} />}
      <meta property="og:site_name" content="Rippling" />
      <meta
        property="article:modified_time"
        content="2020-08-14T22:58:21+00:00"
      />
      <meta property="og:image" content={seoImage} />
      <meta property="og:image:width" content="940" />
      <meta property="og:image:height" content="490" />
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={seoImage} />,
      <meta name="twitter:creator" content="@ripplingapp" />
      <meta name="twitter:site" content="@ripplingapp" />

      <script src={optimizelyScriptUrl}></script>

      {typeof window !== 'undefined' &&
      window.location.pathname === '/interactive-tour'
        ? [
            <script src="https://js.storylane.io/js/v1/player_min.js"></script>,
            <script
              dangerouslySetInnerHTML={{
                __html: `
							function launchStorylaneDemo(){
								new StorylaneEmbed(
									{ dom_id: 'storylane-demo-section', // id of the dom element
										demo_url: 'https://app.storylane.io/demo/ynabww7wzqb1?embed=true'
									});
							}
						`,
              }}
            />,
          ]
        : ''}
      {criticalCSS ? (
        <style
          dangerouslySetInnerHTML={{
            __html: `${criticalCSS}`,
          }}
        />
      ) : (
        ''
      )}
      {missingAtomicCSS ? (
        <style
          dangerouslySetInnerHTML={{
            __html: `${missingAtomicCSS}`,
          }}
        />
      ) : (
        ''
      )}
      {/* Used to fix "rogue referrer problem" https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/ */}
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              originalLocation: document.location.protocol + '//' +
              document.location.hostname +
              document.location.pathname +
              document.location.search
            });`,
        }}
      />
      {/* <!-- Qualified (Start) --> */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,q){w['QualifiedObject']=q;w[q]=w[q]||function(){
            (w[q].q=w[q].q||[]).push(arguments)};})(window,'qualified')`,
        }}
      />
      <script
        async
        src="https://js.qualified.com/qualified.js?token=xiZzNAGkA8hE9UwP"
      />
      {/* <!-- Qualified (End) --> */}
    </Head>
  );
};

export default HeadComponent;
